import React from "react";
import { Route, Switch } from "react-router-dom";
import ConfirmDelivery from "../container/ConfirmDelivery";

const MainBody = (props: any) => {
    const { classes } = props;
    return (
        <div className={classes.content}>
            <div className={classes.container}>
                <Switch>
                    <Route exact path="/" render={(props) => <ConfirmDelivery />} />
                </Switch>
            </div>
        </div>
    );
};

export default MainBody;
