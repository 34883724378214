import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar } from "@material-ui/core";
import logo from "../../assets/images/SwoopLogo.svg";

const Header: FunctionComponent = () => {
    const classes = useStyles();
    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <div style={{ padding: 0, margin: 0 }}>
                    <img
                        className={classes.img}
                        // style={{ cursor: "pointer" }}
                        src={logo}
                        alt="SwoopLogo"
                        // onClick={() => {
                        //     window.location.href = "/";
                        // }}
                    />
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;

const useStyles = makeStyles((theme) => {
    return {
        appBar: {
            height: 60,
            touchAction: "none",
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            justifyContent: "center",
        },
        toolbar: {},
        img: {
            maxWidth: "80vw",
            maxHeight: 50,
        },
    };
});
