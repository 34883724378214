import React from "react";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import { green } from "@material-ui/core/colors";
import { makeStyles, SnackbarContent, Snackbar, IconButton } from "@material-ui/core";

interface ISnackbarMessage {
    open: boolean;
    onClose: any;
    message: string;
    variant: string;
    autoHideDuration?: number;
}

const SnackbarMessage = ({ open, onClose, message, variant, autoHideDuration = 102000 }: ISnackbarMessage) => {
    // variant: success/error
    const classes = useStyles();
    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <CustomSnackbarContent onClose={onClose} variant={variant} message={message} classes={classes} />
        </Snackbar>
    );
};

const variantIcon: any = {
    success: CheckCircleIcon,
    error: ErrorIcon,
};

const CustomSnackbarContent = (props: any) => {
    const { classes, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];
    return (
        <SnackbarContent
            className={classes[variant]}
            aria-describedby="client-snackbar"
            message={
                message && (
                    <div id="client-snackbar" className={classes.message}>
                        <Icon className={clsx(classes.icon, classes.iconVariant)} />
                        {message}
                    </div>
                )
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
};

const useStyles = makeStyles((theme) => ({
    success: { backgroundColor: green[800] },
    error: { backgroundColor: theme.palette.error.dark },
    icon: { fontSize: 20 },
    iconVariant: { opacity: 0.9, marginRight: theme.spacing(2) },
    message: { display: "flex", alignItems: "center" },
}));

export default SnackbarMessage;
