import { makeStyles, Typography } from "@material-ui/core";
import { formatTimestamp, formatQRCode } from "../../lib/util";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
interface IDeliveryCompletedMessage {
    qrCode: string;
    data: any;
}

const DeliveryCompletedMessage = (props: IDeliveryCompletedMessage) => {
    const classes = useStyles();

    const { qrCode, data } = props;
    const _completed_timestamp = data?.completed_at;
    return (
        <div className={classes.messageGrid}>
            <div className={classes.iconDiv}>
                <CheckCircleIcon className={classes.icon} />
            </div>
            <Typography variant="h6">{`Delivery ${formatQRCode(qrCode)} is completed at ${_completed_timestamp ? formatTimestamp(_completed_timestamp) : "0000-00-00"}`}</Typography>
        </div>
    );
};

const useStyles = makeStyles((theme) => {
    return {
        messageGrid: { marginTop: "15%", justifyContent: "center", margin: theme.spacing(4), textAlign: "center" },
        iconDiv: { display: "flex", justifyContent: "center" },
        icon: { fontSize: 50, color: "green", margin: theme.spacing(2) },
    };
});

export default DeliveryCompletedMessage;
