import React from "react";
import { makeStyles, CssBaseline } from "@material-ui/core";
import Header from "../component/common/Header";
import MainBody from "./MainBody";
import "./Layout.css";

const Layout = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <CssBaseline />
                <Header />
                <MainBody classes={classes} />
            </div>
        </>
    );
};

const useStyles = makeStyles((theme) => {
    return {
        root: {
            height: "100vh",
        },
        content: {
            flexGrow: 1,
            overflow: "auto",
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(2),
            marginBottom: theme.spacing(2),
            display: "flex",
            justifyContent: "center",
            height: "calc(100vh - 80px)",
        },
    };
});

export default Layout;
