import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ConfirmAndRateDelivery from "../component/delivery/ConfirmAndRateDelivery";
import QRCodeNotFound from "../component/delivery/QRCodeNotFound";
import DeliveryCompletedMessage from "../component/delivery/DeliveryCompletedMessage";
import BlockingPreloader from "../component/common/BlockingPreloader";
import { getQRtracking } from "../lib/api";
import { parseSearchParams } from "../lib/util";

const ConfirmDelivery = () => {
    const params = parseSearchParams(useLocation().search, ["id"]);
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(true);

    const qrCode: string = params.qrcode || "";

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const _data: any = await getQRtracking(qrCode);
        if (!_data?.error && _data?.id) {
            setData(_data);
        }
        setLoading(false);
    };

    return <>{!loading ? SwichComponents({ data, qrCode, callback: fetchData }) : <BlockingPreloader open={loading} />}</>;
};

const SwichComponents = ({ data, qrCode, callback }: any) => {
    if (data?.complete_status === false) {
        return <ConfirmAndRateDelivery qrCode={qrCode} callback={callback} />;
    } else if (data?.complete_status) {
        return <DeliveryCompletedMessage qrCode={qrCode} data={data} />;
    } else {
        return <QRCodeNotFound qrCode={qrCode} />;
    }
};

export default ConfirmDelivery;
