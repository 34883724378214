import axios from "axios";
import config from "../config.json";

export const getQRtracking = async (qrcode: string) => {
    if (!qrcode) return null;
    const res = await get(`${Urls.TRACKING}/${qrcode}`);
    return res.data;
};

export const saveTrackingConfirmation = async (data: any) => {
    return await post(Urls.TRACKING_CONFIRM, data);
};

const get = async (path: string, type = "logistics") => {
    return await call(await createRequest("GET", path, type));
};

const post = async (path: string, data: any, type = "logistics") => {
    return await call(await createRequest("POST", path, type, data));
};

// const put = async (path:string, data:any, type = "logistics") => {
//     return await call(await createRequest("PUT", path, type, data ));
// };

const createRequest = async (method: string, path: string, type: string, data?: any) => {
    // defining a loose object
    const param: any = {
        method,
        url: config.apiUrl + "/" + type + path,
    };

    if (data) {
        param.data = JSON.stringify(data);
    }

    return param;
};

const call = async (params: any) => {
    try {
        const response = await axios(params);
        return response.data;
    } catch (e) {
        return { status: 500, message: JSON.stringify(e) };
    }
};

const Urls = {
    TRACKING_CONFIRM: "/tracking/confirm",
    TRACKING: "/tracking",
};
