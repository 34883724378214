import { makeStyles, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { formatQRCode } from "../../lib/util";

interface IDeliveryCompletedMessage {
    qrCode: string;
}

const QRCodeNotFound = (props: IDeliveryCompletedMessage) => {
    const classes = useStyles();
    const { qrCode } = props;
    return (
        <div className={classes.messageGrid}>
            <div className={classes.iconDiv}>
                <ErrorIcon className={classes.icon} />
            </div>
            <Typography variant="h6">{`Sorry QR Code ${formatQRCode(qrCode)} is not yet registered`}</Typography>
        </div>
    );
};

const useStyles = makeStyles((theme) => {
    return {
        messageGrid: { marginTop: "15%", justifyContent: "center", margin: theme.spacing(4), textAlign: "center" },
        iconDiv: { display: "flex", justifyContent: "center" },
        icon: { fontSize: 50, color: theme.palette.error.main, margin: theme.spacing(2) },
    };
});

export default QRCodeNotFound;
