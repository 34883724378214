import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Layout from "./container/Layout";

const Routes = () => {
    return (
        <MuiThemeProvider theme={theme}>
            <Router basename={process.env.PUBLIC_URL}>
                <Layout />
            </Router>
        </MuiThemeProvider>
    );
};

const theme = createTheme({
    palette: {
        primary: {
            light: "#DCDDDF",
            main: "#1E2146",
            dark: "#000",
        },
        secondary: {
            main: "#008631",
        },
        error: {
            main: "#f5002d",
        },
    },
    typography: {
        fontFamily: "Sora",
    },
});

export default Routes;
